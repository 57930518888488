<template>
	<section class="hero d-flex align-items-center text-white text-center">
        <div class="container">
            <h1><span class="highlight">Schedule</span></h1>
        </div>
    </section>
	
	<section class="py-3">
        <div class="container">
            <div class="row justify-content-center">

					<table class="table table-schedule table-striped table-dark">
						<thead>
							<tr>
								<th scope="col">Date</th>
								<th scope="col">Event</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<th scope="row">July 25th - July 28th</th>
								<td>DBDC - A Legendary Celebration </td>
							</tr>
							<tr>
								<th scope="row">July 30th - Aug 4th</th>
								<td>Empire Open Dance Festival</td>
							</tr>
							<tr>
								<th scope="row">Aug 20th - Aug 24th</th>
								<td>Capital Dance Championships </td>
							</tr>
							<tr>
								<th scope="row">Aug 28th - Sep 1st</th>
								<td>Embassy Dance Championships</td>
							</tr>
							<tr>
								<th scope="row">Sep 2nd - Sep 7th</th>
								<td>USDC</td>
							</tr>
						</tbody>
					</table>

            </div>
        </div>
    </section>
</template>

<script>
export default {
  name: 'SiteSchedule'
}
</script>

<style scoped>
/* Add scoped styles here */
</style>
