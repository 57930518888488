import { createRouter, createWebHistory } from 'vue-router';
import SiteHome from '@/components/SiteHome.vue';
import SiteAbout from '@/components/SiteAbout.vue';
import SiteServices from '@/components/SiteServices.vue';
import SiteGallery from '@/components/SiteGallery.vue';
import SiteBookAnAppointment from '@/components/SiteBookAnAppointment.vue';
import SiteSchedule from '@/components/SiteSchedule.vue';
import SiteContact from '@/components/SiteContact.vue';

const routes = [{
	path: '/',
	name: 'Home',
	component: SiteHome,
	meta: {
		title: 'ENVY Image Studio - Nadezda Vlasova',
		bodyClass: 'page-bg',
	},
},
{
	path: '/about',
	name: 'About',
	component: SiteAbout,
	meta: {
		title: 'About - ENVY Image Studio - Nadezda Vlasova',
		bodyClass: 'page-bg alternate-bg',
	},
},
{
	path: '/services',
	name: 'Services',
	component: SiteServices,
	meta: {
		title: 'Services - ENVY Image Studio - Nadezda Vlasova',
		bodyClass: '',
	},
},
{
	path: '/gallery',
	name: 'Gallery',
	component: SiteGallery,
	meta: {
		title: 'Gallery - ENVY Image Studio - Nadezda Vlasova',
		bodyClass: 'page-bg alternate-bg',
	},
},
{
	path: '/bookanappointment',
	name: 'Book an Appointment',
	component: SiteBookAnAppointment,
	meta: {
		title: 'Book an Appointment - ENVY Image Studio - Nadezda Vlasova',
		bodyClass: 'page-bg',
	},
},
{
	path: '/schedule',
	name: 'Schedule',
	component: SiteSchedule,
	meta: {
		title: 'Schedule - ENVY Image Studio - Nadezda Vlasova',
		bodyClass: 'page-bg alternate-bg',
	},
},
{
	path: '/contact',
	name: 'Contact Us',
	component: SiteContact,
	meta: {
		title: 'Contact Us - ENVY Image Studio - Nadezda Vlasova',
		bodyClass: 'page-bg',
	},
}];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior() {
		// Always scroll to the top of the page
		return { top: 0 };
	},
});

router.beforeEach((to, from, next) => {
	// Set the document title
	document.title = to.meta.title || 'ENVY Image Studio - Nadezda Vlasova';
	// Set the body class
	document.body.className = to.meta.bodyClass || '';

	next();
});

export default router;