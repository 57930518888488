<template>
	<section class="hero d-flex align-items-center text-white text-center">
        <div class="container">
            <h1><span class="highlight">Our Services</span></h1>
        </div>
    </section>
	
	<section class="py-3">
        <div class="container">
            <div class="row justify-content-center">
                <div class="content-block col-md-10 p-4">

					<h4><span class="highlight">Hairstyle</span></h4>
					<br />
                    <p>Hair appointment includes styling products, hairnets, rubber bands and hairpins as well as glitter and metalic foil.</p>

					<p>You can bring any hairpieces, accessories and hair extensions you would like to use or you can purchase some of ours ( subject to availability).</p>

					<p>Please come with clean, dry and brushed hair (in the case of very curly hair please also straighten it).</p>

					<p>You can bring pictures of the hairstyle you would like to do or make a  decision together with the stylist on the spot.</p>
					
					<br />
					<h4><span class="highlight">Makeup</span></h4>
					<br />
					<p>Makeup appointments include strip eyelashes application and all the products, tools to complete your makeup.</p>

					<p>You can also bring your own makeup products and makeup brushes you would like us to use in case of allergies. We encourage you to bring with you eyelash glue and lipstick to be able to touch up your makeup during the long competition day.</p>
				</div>
            </div>
        </div>
    </section>
	
	<section class="hero d-flex align-items-center text-white text-center py-3">
        <div class="container">
            <h2>Pricing</h2>
        </div>
    </section>
	
	<section>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-10">
					<div class="card-deck mb-3 text-center">
						<div class="card border-secondary bg-dark mb-4 box-shadow text-white">
							<div class="card-body">
								<h5 class="card-title">Hairstyle</h5>
								<div class="card-subtitle pricing-card-title">$125</div>
								<p class="card-text">30-45 minutes</p>
							</div>
							<div class="card-footer">
								<router-link to="/bookanappointment" class="btn btn-lg btn-block btn-light">Book it</router-link>
							</div>
						</div>
						<div class="card border-secondary bg-dark mb-4 box-shadow text-white">
							<div class="card-body">
								<h5 class="card-title">Makeup</h5>
								<div class="card-subtitle pricing-card-title">$125</div>
								<p class="card-text">30-45 minutes</p>
							</div>
							<div class="card-footer">
								<router-link to="/bookanappointment" class="btn btn-lg btn-block btn-light">Book it</router-link>
							</div>
						</div>
						<div class="card border-secondary bg-dark mb-4 box-shadow text-white">
							<div class="card-body">
								<h5 class="card-title">Hairstyle &amp; Makeup</h5>
								<div class="card-subtitle pricing-card-title">$250</div>
								<p class="card-text">1:30-2 hours</p>
							</div>
							<div class="card-footer">
								<router-link to="/bookanappointment" class="btn btn-lg btn-block btn-light">Book it</router-link>
							</div>
						</div>
					</div>
					
					<div class="card-deck mb-3 text-center">
						<div class="card border-secondary bg-dark mb-4 box-shadow text-white">
							<div class="card-body">
								<h5 class="card-title">Next Day Touchup</h5>
								<div class="card-subtit le pricing-card-title">$60</div>
								<p class="card-text">15 minutes</p>
							</div>
							<div class="card-footer">
								<router-link to="/bookanappointment" class="btn btn-lg btn-block btn-light">Book it</router-link>
							</div>
						</div>
						<div class="card border-secondary bg-dark mb-4 box-shadow text-white">
							<div class="card-body">
								<h5 class="card-title">Loose Rhinestone Application</h5>
								<div class="card-subtitle pricing-card-title">$60</div>
								<p class="card-text">15 minutes</p>
							</div>
							<div class="card-footer">
								<router-link to="/bookanappointment" class="btn btn-lg btn-block btn-light">Book it</router-link>
							</div>
						</div>
						<div class="card border-secondary bg-dark mb-4 box-shadow text-white">
							<div class="card-body">
								<h5 class="card-title">Eyelashes Application only</h5>
								<div class="card-subtitle pricing-card-title">$10</div>
								<p class="card-text">---</p>
							</div>
							<div class="card-footer">
								<router-link to="/bookanappointment" class="btn btn-lg btn-block btn-light">Book it</router-link>
							</div>
						</div>
					</div>
					
					<div class="card-deck mb-3 text-center">
						<div class="card border-secondary bg-dark mb-4 box-shadow text-white">
							<div class="card-body">
								<h5 class="card-title">Men Hairstyle</h5>
								<div class="card-subtit le pricing-card-title">$85</div>
								<p class="card-text">30-45 minutes</p>
							</div>
							<div class="card-footer">
								<router-link to="/bookanappointment" class="btn btn-lg btn-block btn-light">Book it</router-link>
							</div>
						</div>
						<div class="card border-secondary bg-dark mb-4 box-shadow text-white">
							<div class="card-body">
								<h5 class="card-title">Men Makeup Face</h5>
								<div class="card-subtit le pricing-card-title">$45</div>
								<p class="card-text">30-45 minutes</p>
							</div>
							<div class="card-footer">
								<router-link to="/bookanappointment" class="btn btn-lg btn-block btn-light">Book it</router-link>
							</div>
						</div>
						<div class="card border-secondary bg-dark mb-4 box-shadow text-white">
							<div class="card-body">
								<h5 class="card-title">Men Makeup Face &amp; Chest</h5>
								<div class="card-subtit le pricing-card-title">$60</div>
								<p class="card-text">30-45 minutes</p>
							</div>
							<div class="card-footer">
								<router-link to="/bookanappointment" class="btn btn-lg btn-block btn-light">Book it</router-link>
							</div>
						</div>
					</div>
					
					<div class="card-deck mb-3 text-center">
						<div class="card border-secondary bg-dark mb-4 box-shadow text-white">
							<div class="card-body">
								<h5 class="card-title">Youth Hairstyle</h5>
								<div class="card-subtit le pricing-card-title">$110</div>
								<p class="card-text">30-45 minutes</p>
								<p class="card-text">For under 18 year olds</p>
							</div>
							<div class="card-footer">
								<router-link to="/bookanappointment" class="btn btn-lg btn-block btn-light">Book it</router-link>
							</div>
						</div>
						<div class="card border-secondary bg-dark mb-4 box-shadow text-white">
							<div class="card-body">
								<h5 class="card-title">Youth Makeup</h5>
								<div class="card-subtit le pricing-card-title">$110</div>
								<p class="card-text">30-45 minutes</p>
								<p class="card-text">For under 18 year olds</p>
							</div>
							<div class="card-footer">
								<router-link to="/bookanappointment" class="btn btn-lg btn-block btn-light">Book it</router-link>
							</div>
						</div>
						<div class="card border-secondary bg-dark mb-4 box-shadow text-white">
							<div class="card-body">
								<h5 class="card-title">Youth Hairstyle &amp; Makeup</h5>
								<div class="card-subtit le pricing-card-title">$220</div>
								<p class="card-text">1:30-2 hours</p>
								<p class="card-text">For under 18 year olds</p>
							</div>
							<div class="card-footer">
								<router-link to="/bookanappointment" class="btn btn-lg btn-block btn-light">Book it</router-link>
							</div>
						</div>
					</div>
                </div>
            </div>
        </div>
    </section>
	
	<section class="hero d-flex align-items-center text-white text-center">
        <div class="container">
            <h2>Payments</h2>
        </div>
    </section>
	
	<section class="py-3">
        <div class="container">
            <div class="row justify-content-center">
                <div class="content-block col-md-10 p-4">
                    <p><strong>Cash</strong>: Please bring exact change.</p>
					<p><strong>Check to</strong>: Nadezda Vlasova</p>
					<p><strong>Venmo</strong>: Nadezda-Vlasova</p>
					<p><strong>Zelle</strong>: 1 (347)481-8056</p>
                </div>
            </div>
        </div>
    </section>
	
    <section class="hero d-flex align-items-center text-white text-center py-3">
        <div class="container">
            <h2>Policy</h2>
			<p>Thank you for choosing our hair and makeup services. <br />
			To ensure the best experience for all our clients, please review our booking and cancellation policies.</p>
        </div>
    </section>
    
    <section class="py-3">
        <div class="container">
            <div class="row justify-content-center">
                <div class="content-block col-md-10 p-4">

					<h4><span class="highlight">Booking Policy</span></h4>
					<br />
                    <p><strong>Appointment Scheduling</strong>: Appointments can be scheduled through our website or by contacting us directly. Please provide all necessary details to ensure we can meet your needs.</p>

					<p><strong>Confirmation</strong>: All appointments will be confirmed via email or phone. Please ensure your contact information is accurate.</p>

					<p><strong>Deposit</strong>: A non-refundable deposit may be required to secure your appointment. The deposit amount will be applied towards your final bill.</p>

					<p><strong>Special Requests</strong>: For any special requests or specific needs, please inform us at the time of booking to allow adequate preparation.</p>
				</div>
            </div>
        </div>
    </section>
	
	<section class="py-3">
        <div class="container">
            <div class="row justify-content-center">
                <div class="content-block col-md-10 p-4">
					<h4><span class="highlight">Cancellation Policy</span></h4>
					<br />

					<p><strong>72-Hour Notice</strong>: Any cancellation request received less than 72 hours prior to your appointment will result in a charge of 100% of the entire booking fee.</p>

					<p><strong>No Show / No Response</strong>: If you fail to show up or do not respond to our attempts to confirm your appointment, you will be charged 100% of the service fee. Additionally, we reserve the right to refuse future appointments.</p>

					<p><strong>Late Arrivals</strong>: Please be punctual for your appointment. If you are more than 15 minutes late, we may need to reschedule your service if it cannot be completed within the remaining time frame.</p>

					<p><strong>Rescheduling</strong>: We understand that unexpected situations may arise. If you need to reschedule your appointment, please contact us as soon as possible. Rescheduling within the 72-hour window will be considered a cancellation and subject to the cancellation fee.</p>
				</div>
            </div>
        </div>
    </section>
	
	<section class="py-3">
        <div class="container">
            <div class="row justify-content-center">
                <div class="content-block col-md-10 p-4">
					<h4><span class="highlight">General Policies</span></h4>
					<br />

					<p><strong>Payment</strong>: Full payment is due at the time of service. We accept cash, credit cards, and other forms of payment as specified on our website.</p>

					<p><strong>Health and Safety</strong>: Please inform us of any allergies or sensitivities prior to your appointment to ensure your safety and comfort.</p>

					<p><strong>Customer Satisfaction</strong>: Your satisfaction is our priority. If you have any concerns or feedback, please do not hesitate to contact us.</p>

                </div>
            </div>
        </div>
    </section>
	
	<section class="py-3">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-10 p-4">
					<p><strong><span class="highlight">By booking an appointment with us, you agree to the terms and conditions outlined in this policy. Thank you for your understanding and cooperation. We look forward to providing you with exceptional hair and makeup services.</span></strong></p>
				</div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
  name: 'SiteServices'
}
</script>

<style scoped>
/* Add scoped styles here */
</style>