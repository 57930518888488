<template>
	<footer class="text-white text-center py-3">
		<div class="container">
			<p class="footer-title">Contact ENVY Image Studio</p>
			<div class="social-icons mb-3">
				<router-link to="/contact"><img src="@/assets/images/icon-email.png" alt="Email"></router-link>
				<a href="https://www.instagram.com/envy_nadezda_vlasova/" target="_blank"><img src="@/assets/images/icon-instagram.png" alt="Instagram"></a>
				<a href="https://www.facebook.com/vlasova.nadezda" target="_blank"><img src="@/assets/images/icon-facebook.png" alt="Facebook"></a>
			</div>
			<p class="footer-copyright">&copy; 2024 ENVY Image Studio</p>
		</div>
	</footer>
</template>

<script>
export default {
  name: 'SiteFooter'
}
</script>

<style scoped>
/* Add scoped styles here */
</style>
