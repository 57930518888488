<template>
	<section class="hero d-flex align-items-center text-white">
        <div class="container">
            <div class="row">
                <div class="col-12 text-center">
                    <h1><span class="highlight">Contact Us</span></h1>
                </div>
            </div>
        </div>
    </section>
    
    <section class="py-3 text-white">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <p><strong>Phone:</strong> +1 (347)481-8056</p>
                </div>
                <div class="content-block col-md-6 p-4">
					<div v-if="formSubmitted" class="success-message">
						<p>Thank you for contacting us! Your message has been sent successfully.</p>
						<button @click="resetForm" class="btn btn-warning btn-block">Send Another Message</button>
					</div>
					
					<div v-else>
						<h2>Send us a message</h2>
						<form @submit.prevent="submitForm">
							<div class="form-row">
								<div class="form-group col-md-6">
									<input type="text" class="form-control contact-input" id="fullName" v-model="form.fullName" placeholder="Full Name" required>
								</div>
								<div class="form-group col-md-6">
									<input type="email" class="form-control contact-input" id="emailAddress" v-model="form.emailAddress" placeholder="Email Address" required>
								</div>
							</div>
							<div class="form-group">
								<input type="text" class="form-control contact-input" id="phone" v-model="form.phone" placeholder="Phone Number" required>
							</div>
							<div class="form-group">
								<textarea class="form-control contact-input" id="message" v-model="form.message" rows="4" placeholder="Message"></textarea>
							</div>
							<div class="col-md-6 contact-submit">
								<button type="submit" class="btn btn-warning btn-block">
									Send Message <span>&#8594;</span>
								</button>
							</div>
						</form>
						<p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
					</div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
  name: 'SiteContact',
  data() {
    return {
      form: {
        fullName: '',
        emailAddress: '',
        phone: '',
        message: ''
      },
      formSubmitted: false,
      errorMessage: '',
    };
  },
  methods: {
    async submitForm() {
      this.errorMessage = '';
      if (!this.form.fullName || !this.form.emailAddress || !this.form.phone) {
        this.errorMessage = 'Please fill in all required fields.';
        return;
      }

      try {
        const response = await fetch(`${process.env.VUE_APP_SITE_URL}/send-email`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(this.form),
        });
        
        if (response.ok) {
          this.formSubmitted = true;
        } else {
          this.errorMessage = 'Failed to send message. Please try again.';
        }
      } catch (error) {
        this.errorMessage = `Error sending message: ${error.message}`;
      }
    },
    resetForm() {
      this.form = {
        fullName: '',
        emailAddress: '',
        phone: '',
        message: '',
      };
      this.formSubmitted = false;
      this.errorMessage = '';
    },
  },
}
</script>

<style scoped>
.success-message {
  color: #e2b06e;
}

.error-message {
  color: red;
}
</style>
