<template>
	<section class="hero d-flex align-items-center text-white">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-md-6">
					<h1>"<span class="highlight">Transform</span> and <span class="highlight">Thrive</span>: You'll Envy the Way You Look, Inside and Out."</h1>
				</div>
				<div class="col-md-6 text-md-right">
					<img src="@/assets/images/nadya_model_front.png" alt="Nadya Model" class="img-fluid" style="max-width: 400px;">
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
  name: 'SiteHome'
}
</script>

<style scoped>
/* Add scoped styles here */
</style>
