<template>
  <header>
    <nav class="navbar navbar-expand-lg custom-navbar">
      <div class="container">
        <router-link class="navbar-brand" to="/">
          <img src="@/assets/images/logo_new.png" alt="ENVY Image Studio Logo" id="nav-logo">
        </router-link>
        <button class="navbar-toggler navbar-dark" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-center" id="navbarNav">
          <ul class="navbar-nav">
            <li class="nav-item"><router-link class="nav-link" to="/" exact-active-class="current">Home</router-link></li>
            <li class="nav-item"><router-link class="nav-link" to="/about" active-class="current">About</router-link></li>
            <li class="nav-item"><router-link class="nav-link" to="/services" active-class="current">Services</router-link></li>
            <li class="nav-item"><router-link class="nav-link" to="/gallery" active-class="current">Gallery</router-link></li>
            <li class="nav-item"><router-link class="nav-link" to="/bookanappointment" active-class="current">Book an Appointment</router-link></li>
            <li class="nav-item"><router-link class="nav-link" to="/schedule" active-class="current">Schedule</router-link></li>
            <li class="nav-item"><router-link class="nav-link" to="/contact" active-class="current">Contact</router-link></li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'SiteHeader'
}
</script>

<style scoped>
/* Add scoped styles here */
</style>
