<script>
import { Fancybox } from '@fancyapps/ui';

export default {
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    this.initFancybox();
  },
  updated() {
    this.refreshFancybox();
  },
  beforeUnmount() {
    this.destroyFancybox();
  },
  methods: {
    initFancybox() {
      if (this.$refs.container) {
        Fancybox.bind(this.$refs.container,'[data-fancybox]', {
          ...(this.options || {}),
          dragToClose: false,
          Toolbar: {
            display: {
              left: ['close'],
              middle: [],
              right: [],
            },
          },
          Images: {
            zoom: false,
          },
          Thumbs: {
            type: 'classic',
          },
          Carousel: {
            transition: false,
            friction: 0,
          },
          on: {
            'Carousel.ready Carousel.change': (fancybox) => {
              fancybox.container.style.setProperty(
                '--bg-image',
                `url("${fancybox.getSlide().thumbSrc}")`
              );
            },
            /*init: () => {
              console.info('fancybox init');
            },
            done: (fancybox) => {
              console.log(fancybox);
            },*/
          }
        });
      }
    },
    refreshFancybox() {
      if (this.$refs.container) {
        this.destroyFancybox();
        this.initFancybox();
      }
    },
    destroyFancybox() {
      if (this.$refs.container) {
        Fancybox.destroy();
      }
    }
  }
};
</script>

<template>
  <div ref="container">
    <slot></slot>
  </div>
</template>

<style>
@import '@fancyapps/ui/dist/fancybox/fancybox.css';
</style>