<template>
	<div id="app">
		<SiteHeader />
		<router-view />
		<SiteFooter />
	</div>
</template>

<script>
import SiteHeader from './components/SiteHeader.vue'
import SiteFooter from './components/SiteFooter.vue'

export default {
  name: 'App',
  components: {
    SiteHeader,
    SiteFooter
  }
}
</script>

<style src="./assets/styles/main.scss" lang="scss"></style>
