<template>
  <section class="hero text-white text-center">
    <div class="container">
      <h1><span class="highlight">Gallery</span></h1>
    </div>
  </section>
  <section class="gallery py-3">
    <div class="container">
      <div class="row">
        <div class="col-md-2">
          <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
            <a class="nav-link active" id="v-pills-bridal-tab" data-toggle="pill" href="#v-pills-bridal" role="tab" aria-controls="v-pills-bridal" aria-selected="true">Bridal</a>
            <a class="nav-link" id="v-pills-editorial-tab" data-toggle="pill" href="#v-pills-editorial" role="tab" aria-controls="v-pills-editorial" aria-selected="false">Editorial</a>
            <a class="nav-link" id="v-pills-ballroom-tab" data-toggle="pill" href="#v-pills-ballroom" role="tab" aria-controls="v-pills-ballroom" aria-selected="false">Ballroom</a>
          </div>
        </div>
        <div class="col-md-10">
          <div class="tab-content" id="v-pills-tabContent">
            <div class="tab-pane fade show active" id="v-pills-bridal" role="tabpanel" aria-labelledby="v-pills-bridal-tab">
              <Fancybox class="container">
                <div v-for="(row, rowIndex) in chunkedGalleryBridal" :key="rowIndex" class="row justify-content-md-center">
                  <div v-for="(photo, colIndex) in row" :key="colIndex" class="col-md-3">
                    <a v-if="photo && photo.src" data-fancybox="group" :href="photo.src">
                      <img :src="photo.srcThumb" class="rounded" />
                    </a>
                    <div v-else>
                      <!-- Fallback content or error handling -->
                      <p>Image not available</p>
                    </div>
                  </div>
                </div>
              </Fancybox>
            </div>
            <div class="tab-pane fade" id="v-pills-editorial" role="tabpanel" aria-labelledby="v-pills-editorial-tab">
              <Fancybox class="container">
                <div v-for="(row, rowIndex) in chunkedGalleryEditorial" :key="rowIndex" class="row justify-content-md-center">
                  <div v-for="(photo, colIndex) in row" :key="colIndex" class="col-md-3">
                    <a v-if="photo && photo.src" data-fancybox="group" :href="photo.src">
                      <img :src="photo.srcThumb" class="rounded" />
                    </a>
                    <div v-else>
                      <!-- Fallback content or error handling -->
                      <p>Image not available</p>
                    </div>
                  </div>
                </div>
              </Fancybox>
            </div>
            <div class="tab-pane fade" id="v-pills-ballroom" role="tabpanel" aria-labelledby="v-pills-ballroom-tab">
              <Fancybox class="container">
                <div v-for="(row, rowIndex) in chunkedGalleryBallroom" :key="rowIndex" class="row justify-content-md-center">
                  <div v-for="(photo, colIndex) in row" :key="colIndex" class="col-md-3">
                    <a v-if="photo && photo.src" data-fancybox="group" :href="photo.src">
                      <img :src="photo.srcThumb" class="rounded" />
                    </a>
                    <div v-else>
                      <!-- Fallback content or error handling -->
                      <p>Image not available</p>
                    </div>
                  </div>
                </div>
              </Fancybox>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Fancybox from './ComponentGallery.vue';

export default {
  name: 'SiteGallery',
  components: {
    Fancybox,
  },
  data() {
    return {
      galleryBridal: [
        { src: require('@/assets/images/gallery/bridal/bridal_1.jpg'), srcThumb: require('@/assets/images/gallery/bridal/bridal_1_thumb.jpg') },
        { src: require('@/assets/images/gallery/bridal/bridal_2.jpg'), srcThumb: require('@/assets/images/gallery/bridal/bridal_2_thumb.jpg') },
        { src: require('@/assets/images/gallery/bridal/bridal_3.jpg'), srcThumb: require('@/assets/images/gallery/bridal/bridal_3_thumb.jpg') },
        { src: require('@/assets/images/gallery/bridal/bridal_4.jpg'), srcThumb: require('@/assets/images/gallery/bridal/bridal_4_thumb.jpg') },
      ],
      galleryEditorial: [
        { src: require('@/assets/images/gallery/editorial/editorial_1.jpg'), srcThumb: require('@/assets/images/gallery/editorial/editorial_1_thumb.jpg') },
        { src: require('@/assets/images/gallery/editorial/editorial_2.jpg'), srcThumb: require('@/assets/images/gallery/editorial/editorial_2_thumb.jpg') },
        { src: require('@/assets/images/gallery/editorial/editorial_3.jpg'), srcThumb: require('@/assets/images/gallery/editorial/editorial_3_thumb.jpg') },
        { src: require('@/assets/images/gallery/editorial/editorial_4.jpg'), srcThumb: require('@/assets/images/gallery/editorial/editorial_4_thumb.jpg') },
        { src: require('@/assets/images/gallery/editorial/editorial_5.jpg'), srcThumb: require('@/assets/images/gallery/editorial/editorial_5_thumb.jpg') },
        { src: require('@/assets/images/gallery/editorial/editorial_6.jpg'), srcThumb: require('@/assets/images/gallery/editorial/editorial_6_thumb.jpg') },
        { src: require('@/assets/images/gallery/editorial/editorial_7.jpg'), srcThumb: require('@/assets/images/gallery/editorial/editorial_7_thumb.jpg') },
        { src: require('@/assets/images/gallery/editorial/editorial_8.jpg'), srcThumb: require('@/assets/images/gallery/editorial/editorial_8_thumb.jpg') },
        { src: require('@/assets/images/gallery/editorial/editorial_9.jpg'), srcThumb: require('@/assets/images/gallery/editorial/editorial_9_thumb.jpg') },
        { src: require('@/assets/images/gallery/editorial/editorial_10.jpg'), srcThumb: require('@/assets/images/gallery/editorial/editorial_10_thumb.jpg') },
        { src: require('@/assets/images/gallery/editorial/editorial_11.jpg'), srcThumb: require('@/assets/images/gallery/editorial/editorial_11_thumb.jpg') },
        { src: require('@/assets/images/gallery/editorial/editorial_12.jpg'), srcThumb: require('@/assets/images/gallery/editorial/editorial_12_thumb.jpg') }
      ],
      galleryBallroom: [
        { src: require('@/assets/images/gallery/ballroom/ballroom_1.jpg'), srcThumb: require('@/assets/images/gallery/ballroom/ballroom_1_thumb.jpg') },
        { src: require('@/assets/images/gallery/ballroom/ballroom_2.jpg'), srcThumb: require('@/assets/images/gallery/ballroom/ballroom_2_thumb.jpg') },
        { src: require('@/assets/images/gallery/ballroom/ballroom_3.jpg'), srcThumb: require('@/assets/images/gallery/ballroom/ballroom_3_thumb.jpg') },
        { src: require('@/assets/images/gallery/ballroom/ballroom_4.jpg'), srcThumb: require('@/assets/images/gallery/ballroom/ballroom_4_thumb.jpg') },
        { src: require('@/assets/images/gallery/ballroom/ballroom_5.jpg'), srcThumb: require('@/assets/images/gallery/ballroom/ballroom_5_thumb.jpg') },
        { src: require('@/assets/images/gallery/ballroom/ballroom_6.jpg'), srcThumb: require('@/assets/images/gallery/ballroom/ballroom_6_thumb.jpg') },
        { src: require('@/assets/images/gallery/ballroom/ballroom_7.jpg'), srcThumb: require('@/assets/images/gallery/ballroom/ballroom_7_thumb.jpg') },
        { src: require('@/assets/images/gallery/ballroom/ballroom_8.jpg'), srcThumb: require('@/assets/images/gallery/ballroom/ballroom_8_thumb.jpg') },
        { src: require('@/assets/images/gallery/ballroom/ballroom_9.jpg'), srcThumb: require('@/assets/images/gallery/ballroom/ballroom_9_thumb.jpg') },
        { src: require('@/assets/images/gallery/ballroom/ballroom_10.jpg'), srcThumb: require('@/assets/images/gallery/ballroom/ballroom_10_thumb.jpg') },
        { src: require('@/assets/images/gallery/ballroom/ballroom_11.jpg'), srcThumb: require('@/assets/images/gallery/ballroom/ballroom_11_thumb.jpg') },
        { src: require('@/assets/images/gallery/ballroom/ballroom_12.jpg'), srcThumb: require('@/assets/images/gallery/ballroom/ballroom_12_thumb.jpg') },
        { src: require('@/assets/images/gallery/ballroom/ballroom_13.jpg'), srcThumb: require('@/assets/images/gallery/ballroom/ballroom_13_thumb.jpg') },
        { src: require('@/assets/images/gallery/ballroom/ballroom_14.jpg'), srcThumb: require('@/assets/images/gallery/ballroom/ballroom_14_thumb.jpg') },
        { src: require('@/assets/images/gallery/ballroom/ballroom_15.jpg'), srcThumb: require('@/assets/images/gallery/ballroom/ballroom_15_thumb.jpg') },
        { src: require('@/assets/images/gallery/ballroom/ballroom_16.jpg'), srcThumb: require('@/assets/images/gallery/ballroom/ballroom_16_thumb.jpg') },
        { src: require('@/assets/images/gallery/ballroom/ballroom_17.jpg'), srcThumb: require('@/assets/images/gallery/ballroom/ballroom_17_thumb.jpg') },
        { src: require('@/assets/images/gallery/ballroom/ballroom_18.jpg'), srcThumb: require('@/assets/images/gallery/ballroom/ballroom_18_thumb.jpg') },
        { src: require('@/assets/images/gallery/ballroom/ballroom_19.jpg'), srcThumb: require('@/assets/images/gallery/ballroom/ballroom_19_thumb.jpg') },
        { src: require('@/assets/images/gallery/ballroom/ballroom_20.jpg'), srcThumb: require('@/assets/images/gallery/ballroom/ballroom_20_thumb.jpg') },
        { src: require('@/assets/images/gallery/ballroom/ballroom_21.jpg'), srcThumb: require('@/assets/images/gallery/ballroom/ballroom_21_thumb.jpg') },
        { src: require('@/assets/images/gallery/ballroom/ballroom_22.jpg'), srcThumb: require('@/assets/images/gallery/ballroom/ballroom_22_thumb.jpg') },
      ],
    };
  },
  computed: {
    chunkedGalleryBridal() {
      const chunkSize = 4;
      const chunks = [];

      for (let i = 0; i < this.galleryBridal.length; i += chunkSize) {
        const chunk = this.galleryBridal.slice(i, i + chunkSize);
        chunks.push(chunk);
      }

      return chunks;
    },
    chunkedGalleryEditorial() {
      const chunkSize = 4;
      const chunks = [];

      for (let i = 0; i < this.galleryEditorial.length; i += chunkSize) {
        const chunk = this.galleryEditorial.slice(i, i + chunkSize);
        chunks.push(chunk);
      }

      return chunks;
    },
    chunkedGalleryBallroom() {
      const chunkSize = 4;
      const chunks = [];

      for (let i = 0; i < this.galleryBallroom.length; i += chunkSize) {
        const chunk = this.galleryBallroom.slice(i, i + chunkSize);
        chunks.push(chunk);
      }

      return chunks;
    }
  }
}
/*
<section class="gallery py-3 text-center">
    <h3 id="bridal">Bridal</h3>
    <Fancybox class="container">
      <div v-for="(row, rowIndex) in chunkedGalleryBridal" :key="rowIndex" class="row justify-content-md-center">
        <div v-for="(photo, colIndex) in row" :key="colIndex" class="col-md-2">
          <a v-if="photo && photo.src" data-fancybox="group" :href="photo.src">
            <img :src="photo.srcThumb" class="rounded" style="width: 100%;" />
          </a>
          <div v-else>
            <!-- Fallback content or error handling -->
            <p>Image not available</p>
          </div>
        </div>
      </div>
    </Fancybox>
  </section>
  
  <section class="gallery py-3 text-center">
    <h3 id="editorial">Editorial</h3>
    <Fancybox class="container">
      <div v-for="(row, rowIndex) in chunkedGalleryEditorial" :key="rowIndex" class="row justify-content-md-center">
        <div v-for="(photo, colIndex) in row" :key="colIndex" class="col-md-2">
          <a v-if="photo && photo.src" data-fancybox="group" :href="photo.src">
            <img :src="photo.srcThumb" class="rounded" style="width: 100%;" />
          </a>
          <div v-else>
            <!-- Fallback content or error handling -->
            <p>Image not available</p>
          </div>
        </div>
      </div>
    </Fancybox>
  </section>
  
  <section class="gallery py-3 text-center">
    <h3 id="ballroom">Ballroom</h3>
    <Fancybox class="container">
      <div v-for="(row, rowIndex) in chunkedGalleryBallroom" :key="rowIndex" class="row justify-content-md-center">
        <div v-for="(photo, colIndex) in row" :key="colIndex" class="col-md-2">
          <a v-if="photo && photo.src" data-fancybox="group" :href="photo.src">
            <img :src="photo.srcThumb" class="rounded" style="width: 100%;" />
          </a>
          <div v-else>
            <!-- Fallback content or error handling -->
            <p>Image not available</p>
          </div>
        </div>
      </div>
    </Fancybox>
  </section>
  */
</script>

<style>
.fancybox__backdrop::after {
  content: '';
  position: absolute;
  width: 10%;
  height: 10%;
  filter: blur(2px);
  left: 50%;
  top: 50%;
  transform: scale(11);
  opacity: 0.3;
  background-image: var(--bg-image);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.fancybox__toolbar {
  padding: 16px;
}

.fancybox__toolbar,
.fancybox__nav {
  --f-button-border-radius: 50%;
  --f-button-bg: rgb(91 78 76 / 64%);
  --f-button-hover-bg: rgb(91 78 76 / 74%);
  --f-button-active-bg: rgb(91 78 76 / 84%);
}

.fancybox__nav {
  --f-button-svg-width: 22px;
  --f-button-svg-height: 22px;
}

.fancybox__thumbs.is-classic {
  --f-thumb-width: 48px;
  --f-thumb-height: 48px;
  --f-thumb-gap: 16px;

  --f-thumb-border-radius: 6px;
  --f-thumb-outline: 0;
}

.gallery .row+.row {
    margin-top: 1rem;
}

.nav-pills .nav-link {
  color: #fff;
  font-size: 1.3rem;
}

.nav-pills .nav-link.active {
  background-color: #6c757d38 !important;
  color: #E2B06E;
}
</style>
