<template>
	<section class="hero d-flex align-items-center text-white">
        <div class="container">
            <div class="row">
                <div class="col-12 text-center">
                    <h1><span class="highlight">NADEZDA VLASOVA</span></h1>
					<h5>Founder, CEO</h5>
					<p>Top hair stylist and makeup artist.</p>
                </div>
            </div>
        </div>
    </section>
    
    <section class="text-white">
        <div class="container">
            <div class="row align-items-center">
                <div class="align-self-start col-md-6 mb-4 mb-md-0">
                    <img src="@/assets/images/nadya_model_side.png" alt="Nadya Model" class="img-fluid">
                </div>
                <div class="col-md-6 page-text">
					<p>Originally  from Saint Petersburg, Russia, Nadezda moved to the USA in 2008.</p>
					<p>Being a professional Ballroom Dancer for over 28 years she has reached significant milestones, including becoming  the USA Dance Amateur Latin and Showdance National Champion and representing the US at the 2013 World Dancesport Games in Taiwan. She has also been a finalist at prestigious competitions such as the Blackpool, UK Open, International, Dutch Open, and USDC Professional Rising Star Latin events.</p>

					<p>In addition to dance, Nadezda has  a deep love for fashion and keeps up with the latest trends in costumes, accessories, makeup and unique hairdos. Often referred to as the "Carrie Bradshaw" of the ballroom world, she is known for her unique style and fashion-forward looks on and off the dance floor.</p>

					<p>Today, she specializes in creating custom-made hair accessories for clients and styling ballroom hair and makeup at dance competitions. Since 2016 she has worked on special events, weddings, photoshoots, ballroom dance competitions and television, including Bravo TV's "Dancing Queens" reality show.</p>

					<p>Nadezda is  a certified makeup artist from "Mosmake" and a certified ballroom hairstylist from "Image Maximum Pro". Her ability to understand her clients preferences, occasions and needs makes her a high demand specialist .She tailors her services to enhance the natural beauty of each individual and showcase uniqueness of their character. From classic and retro, to daring and avant-garde styles and everything in between, Nadezda believes there is no limit to creativity.</p>
                </div>
            </div>
        </div>
    </section>
	
	<section class="hero py-3 d-flex align-items-center text-white">
        <div class="container">
            <div class="row">
                <div class="col-12 text-center">
                    <h2><span class="highlight">VALERIIA BELOZEROVA</span></h2>
					<p>Top makeup artist of Envy Image Studio.</p>
                </div>
            </div>
        </div>
    </section>
    
    <section class="text-white">
        <div class="container">
            <div class="row align-items-center">
                <div class="align-self-start col-md-6 mb-4 mb-md-0">
                    <img src="@/assets/images/gallery/valeriia_belozerova-new.png" alt="Valeriia Belozerova" class="img-fluid">
                </div>
                <div class="col-md-6 page-text">
					<p>Valeriia, originally from Russia and now based in the USA, is a seasoned professional ballroom dancer with over 25 years of experience. She showcased her talent as a Pro on "Dancing with the Stars" in Italy. Valeriia continues to compete actively, boasting numerous victories in both US and international competitions.</p> 
					
					<p>As a makeup artist she is known for making faces shine. Valeriia's high profile clients love her for the special way she brings out their beauty. Her artistry extends to various facets of makeup, from subtle and natural looks to bold and avant-garde expressions, showcasing her versatility and adaptability. She turns makeup into a happy experience, leaving smiles on faces and hearts full of gratitude.</p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
  name: 'SiteAbout'
}
</script>

<style scoped>
/* Add scoped styles here */
</style>
